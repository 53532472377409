import React, { useEffect, useMemo, useState } from 'react';
import { ColumnWithStrictAccessor, UseFiltersOptions, UseSortByOptions } from 'react-table';
import { Box, Flex, useToast } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';

import { useSuperUsersQuery, useUpdateUserRolesMutation } from '@/store/general';

import { SelectColumnFilter, SearchColumnFilter } from '@/components/column-filters';
import { UiIcon } from '@/components/ui-components';
import { NoContacts } from '@/components/ui-placeholder';

import Table from '@/components/Table';
import EditRoles from './EditRoles';

const SuperUsers = () => {
  const toast = useToast();

  const [selectedUser, selectUser] = useState<undefined | SuperUser>();
  const [saveRoles, setSaveRoles] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [selectedRoles, selectRoles] = useState<Role[]>([]);
  const [initialRoles, setInitialRoles] = useState<Role[]>([]);

  const { data: users, isLoading: isLoadingUsers } = useSuperUsersQuery();

  const [updateUserRoles] = useUpdateUserRolesMutation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onCloseModal = () => {
    selectUser(undefined);
    onClose();
  };

  const onSaveChanges = () => setSaveRoles(true);

  useEffect(() => {
    const _user = users?.find(user => user.keycloakId === selectedUser?.keycloakId);
    selectRoles(_user?.roles || []);
    setInitialRoles(_user?.roles || []);
  }, [selectedUser?.keycloakId, users]);

  const handleChange = (role: Role) => {
    const _roles = [...selectedRoles];
    const roleIndex = selectedRoles.findIndex(r => r === role);

    if (roleIndex === -1) {
      _roles.push(role);
    } else {
      _roles.splice(roleIndex, 1);
    }

    setHasChanges(!(_roles.every(role => initialRoles.includes(role)) && initialRoles.length === _roles.length));
    selectRoles(_roles);
  };

  useEffect(() => {
    if (saveRoles && selectedUser?.keycloakId) {
      setIsLoading(true);

      updateUserRoles({
        keycloakId: selectedUser?.keycloakId,
        roles: selectedRoles,
      }).then(result => {
        const isSuccess = 'data' in result;

        let title: string | undefined = '';
        let description: string | undefined = undefined;
        const status = isSuccess ? 'success' : 'warning';

        if (isSuccess) {
          title = `Roles for ${selectedUser?.email} successfully saved`;
        } else {
          const { error } = result;

          title = 'error' in error ? error.error : 'name' in error ? error.name : undefined;
          description = 'data' in error ? (error.data as string) : 'message' in error ? error.message : undefined;
        }

        title &&
          toast({
            status,
            title,
            description,
            isClosable: true,
            duration: 5000,
            position: 'bottom',
          });

        onClose();
        setIsLoading(false);
        setHasChanges(false);
        selectUser(undefined);
        setSaveRoles(false);
        selectRoles([]);
      });
    }
  }, [saveRoles, updateUserRoles]);

  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const columns: (ColumnWithStrictAccessor<Column> & UseSortByOptions<Column> & UseFiltersOptions<Column>)[] = useMemo(
    () => [
      {
        Header: <Box pl="5">Email</Box>,
        accessor: 'email',
        name: 'Email',
        width: 3,
        disableToggle: true,
        Filter: SearchColumnFilter,
        Cell: ({ cell: { value } }) => (
          <Flex align="center">
            <UiIcon name="user" color="grey-darker" mr="2" />

            <Box fontWeight="semibold" color="grey-darker">
              {value}
            </Box>
          </Flex>
        ),
      },
      {
        Header: 'Keycloak ID',
        accessor: 'keycloakID',
        name: 'Keycloak ID',
        width: 2,
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Roles',
        accessor: 'roles',
        name: 'Roles',
        width: 5,
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'includesAll',
        Cell: ({ row: { values } }) => <div>{values.roles.join(', ')}</div>,
      },
      {
        Header: '',
        accessor: 'edit',
        disableToggle: true,
        disableSortBy: true,
        disableFilters: true,
        width: '50px',
        minWidth: 50,
      },
    ],
    []
  );

  const onEdit = (user: SuperUser) => () => {
    selectUser(user);
    onOpen();
  };

  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const content = useMemo(
    () =>
      users?.map(user => ({
        keycloakID: user.keycloakId,
        email: user.email,
        roles: user.roles,
        edit: (
          <Box
            cursor="pointer"
            color="grey"
            textAlign="center"
            lineHeight={1}
            p="1"
            _hover={{ color: 'primary' }}
            onClick={onEdit(user)}
          >
            <UiIcon name="edit" />
          </Box>
        ),
      })) || [],
    [users]
  );

  // react-table requires initialState.sortBy and initialState.filters to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useSortBy#table-options
  // https://react-table-v7.tanstack.com/docs/api/useFilters#table-options
  const options = {
    initialState: {
      sortBy: useMemo(
        () => [
          {
            id: 'email',
            desc: false,
          },
        ],
        []
      ),
      filters: useMemo(() => [], []),
    },
    defaultColumn: {
      disableFilters: false,
    },
    disableSortBy: false,
  };

  return (
    <Box maxW="full">
      <Box fontSize="2xl" fontWeight="medium" mb="5">
        Users
      </Box>

      <Table
        isLoading={isLoadingUsers}
        placeholder={<NoContacts />}
        placeholderText="No users found"
        data={content}
        columns={columns}
        options={options}
      />

      {isOpen ? (
        <EditRoles
          selectedRoles={selectedRoles}
          user={selectedUser}
          isOpen={isOpen}
          isLoading={isLoading}
          buttonDisabled={!hasChanges}
          onChange={handleChange}
          onSave={onSaveChanges}
          onClose={onCloseModal}
        />
      ) : null}
    </Box>
  );
};

export default SuperUsers;
