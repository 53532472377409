import React, { useMemo, useState, useEffect } from 'react';
import { ColumnWithStrictAccessor, UseSortByOptions } from 'react-table';
import { Box, useToast } from '@chakra-ui/react';

import { useFeaturesQuery, useEnvironmentsQuery, useUpdateFeaturesMutation } from '@/store/general';

import { UiButton, UiSelect } from '@/components/ui-components';

import Table from '@/components/Table';

type FeaturePossibleValues = { [key: string]: string[] };

const Features = () => {
  const toast = useToast();

  const [initialValues, setInitialValues] = useState<FeaturesUpdates>({});
  const [featuresPossibleValues, setFeaturesPossibleValues] = useState<FeaturePossibleValues>({});

  const [featuresUpdates, setFeaturesUpdates] = useState<FeaturesUpdates>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [saveChanges, setSaveChanges] = useState<boolean>(false);

  const { data: features, isLoading: isLoadingFeatures, isSuccess: isSuccessFeatures } = useFeaturesQuery();
  const { data: environments, isLoading: isLoadingEnvironments } = useEnvironmentsQuery();
  const [updateFeatures] = useUpdateFeaturesMutation();

  const _features = features || ({} as FeatureList);
  const _environments = environments || ([] as Environment[]);

  useEffect(() => {
    if (isSuccessFeatures) {
      const initial: FeaturesUpdates = {};
      const _possibleValues: FeaturePossibleValues = {};

      Object.entries(features).forEach(([feature, values]) => {
        Object.entries(values).forEach(([envID, val]) => {
          initial[envID] = { ...initial[envID], [feature]: val.value };
          _possibleValues[feature] = [...val.possibleValues];
        });
      });

      setFeaturesPossibleValues(_possibleValues);
      setInitialValues(initial);
    }
  }, [isSuccessFeatures]);

  const onChange = (feature: string, envID: string) => (value: string) =>
    setFeaturesUpdates(prevState => {
      if (initialValues[envID][feature] === value && prevState[envID]?.[feature]) {
        const updatedPrevState = { ...prevState };

        delete updatedPrevState[envID][feature];

        if (!Object.keys(updatedPrevState[envID]).length) {
          delete updatedPrevState[envID];
        }

        setHasChanges(!!Object.keys(updatedPrevState).length);

        return updatedPrevState;
      }

      setHasChanges(true);

      return {
        ...prevState,
        [envID]: {
          ...prevState[envID],
          [feature]: value,
        },
      };
    });

  useEffect(() => {
    if (saveChanges) {
      setIsLoading(true);

      Object.entries(featuresUpdates).forEach(([envID, features]) => {
        const update: UpdateFeature[] = [];

        Object.entries(features).forEach(([key, value]) => {
          update.push({ environmentId: envID, name: key, value });
        });

        updateFeatures({ environmentId: envID, features: update }).then(result => {
          const isSuccess = 'data' in result;

          let title: string | undefined = '';
          let description: string | undefined = undefined;
          const status = isSuccess ? 'success' : 'warning';

          if (isSuccess) {
            title = `Changes for enviroment ${envID} saved`;
          } else {
            const { error } = result;

            title = 'error' in error ? error.error : 'name' in error ? error.name : undefined;
            description = 'data' in error ? (error.data as string) : 'message' in error ? error.message : undefined;
          }

          title &&
            toast({
              status,
              title,
              description,
              isClosable: true,
              duration: 5000,
              position: 'bottom',
            });

          if (isSuccess) {
            setIsLoading(false);
            setHasChanges(false);
            setSaveChanges(false);
            setFeaturesUpdates({});
          }
        });
      });
    }
  }, [saveChanges, updateFeatures]);

  const hiddenColumns: string[] = [];
  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const columns: (ColumnWithStrictAccessor<Column> & UseSortByOptions<Column>)[] = useMemo(
    () => [
      {
        Header: 'Features',
        disableToggle: true,
        accessor: 'feature',
      },
      ..._environments.map(({ id, name }, index) => {
        index > 6 && hiddenColumns.push(id);

        return {
          Header: (
            <Box fontWeight="semibold" color="grey-darker">
              {name}
            </Box>
          ),
          name,
          accessor: id,
          disableSortBy: true,
        };
      }),
    ],
    [_environments]
  );

  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const content = useMemo(
    () =>
      Object.entries(_features).map(([key, envs]) => {
        const featureData: Column = {};

        _environments.forEach(({ id }) => {
          const options = (featuresPossibleValues?.[key] || []).map((value: string) => ({
            label: value,
            value,
          }));

          featureData[id] = <UiSelect options={options} defaultValue={envs[id]?.value} onChange={onChange(key, id)} />;
        });

        return {
          feature: key,
          ...featureData,
        };
      }),
    [_features, onChange]
  );

  // react-table requires initialState.sortBy and initialState.filters to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useSortBy#table-options
  // https://react-table-v7.tanstack.com/docs/api/useFilters#table-options
  const options = {
    initialState: {
      sortBy: useMemo(
        () => [
          {
            id: 'feature',
            desc: false,
          },
        ],
        []
      ),
      hiddenColumns,
    },
    disableSortBy: false,
  };

  const onSaveChanges = () => setSaveChanges(true);

  return (
    <div>
      <Box mb="5" fontSize="2xl" fontWeight="medium">
        Features
      </Box>

      <Table
        isLoading={isLoadingFeatures && isLoadingEnvironments}
        data={content}
        columns={columns}
        options={options}
      />

      <Box display="flex" flexDirection="column" alignItems="end" mt="5" width="full">
        <UiButton onClick={onSaveChanges} disabled={!hasChanges} isLoading={isLoading}>
          Save
        </UiButton>
      </Box>
    </div>
  );
};

export default Features;
