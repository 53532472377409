import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Checkbox: ComponentStyleConfig = {
  baseStyle: ({ colorScheme }) => ({
    label: {
      fontSize: 'md',
      mx: undefined,
      ml: '10px',
    },
    control: {
      borderColor: 'grey',
      border: '1px solid',

      _checked: {
        bg: colorScheme,
        borderColor: colorScheme,
        color: 'white',
        outline: 'none',

        _hover: {
          bg: `${colorScheme}-dark`,
          borderColor: `${colorScheme}-dark`,
        },

        _disabled: {
          borderColor: `${colorScheme}-lightest`,
          bg: `${colorScheme}-lighter`,
          color: 'white',
        },
      },

      _hover: {
        borderColor: colorScheme,
      },

      _disabled: {
        bg: `${colorScheme}-lighter`,
        borderColor: `${colorScheme}-lighter`,
      },

      _focus: {
        boxShadow: 'none',
        outline: 'none',
      },
    },
  }),
  sizes: {
    small: {
      control: {
        w: '16px',
        h: '16px',
      },
      icon: {
        fontSize: '8px',
      },
    },
    medium: {
      control: {
        w: '20px',
        h: '20px',
      },
      icon: {
        fontSize: '10px',
      },
    },
    large: {
      control: {
        w: '24px',
        h: '24px',
      },
      icon: {
        fontSize: '12px',
      },
    },
  },
  variants: {
    circle: {
      control: {
        borderRadius: 'full',
      },
    },
    square: {
      control: {
        borderRadius: 'default',
      },
    },
  },
  defaultProps: {
    size: 'medium',
    variant: 'circle',
  },
};

export default Checkbox;
