import { extendTheme } from '@chakra-ui/react';

import general from './general';
import components from './components';
import config from './config';

const theme = extendTheme({
  ...config.theme,
  ...general,
  components,
});

export default theme;
