import React, { useCallback } from 'react';
import { Box, Checkbox, Spinner, Flex } from '@chakra-ui/react';

import { useSuperUserRolesQuery } from '@/store/general';

import { UiModal } from '@/components/ui-components';

type EditRolesType = {
  selectedRoles: Role[];
  user?: SuperUser;
  isOpen: boolean;
  isLoading?: boolean;
  buttonDisabled?: boolean;
  onClose: () => void;
  onSave: () => void;
  onChange: (role: Role) => void;
};

const EditRoles = ({
  selectedRoles,
  user,
  isOpen,
  isLoading,
  buttonDisabled,
  onClose,
  onSave,
  onChange,
}: EditRolesType) => {
  const { data: roles, isLoading: isLoadingRoles } = useSuperUserRolesQuery();

  const onCloseModal = useCallback(() => onClose(), [onClose]);
  const onSaveModal = useCallback(() => onSave(), [onSave]);
  const onChangeRole = (role: Role) => () => onChange(role);

  return (
    <UiModal
      title={`Edit ${user?.email} roles`}
      size="large"
      isLoading={isLoading}
      isOpen={isOpen}
      buttonDisabled={buttonDisabled}
      onClose={onCloseModal}
      onSave={onSaveModal}
    >
      {isLoadingRoles ? (
        <Box position="relative" h="300px">
          <Spinner
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            m="auto"
            width="50px"
            height="50px"
            color="primary"
            thickness="4px"
            speed="0.75s"
          />
        </Box>
      ) : (
        <Flex wrap="wrap" mx="-4">
          {(roles || []).map(role => (
            <Box w="1-2" px="4" pt="1" key={role}>
              <Checkbox colorScheme="primary" isChecked={selectedRoles.includes(role)} onChange={onChangeRole(role)}>
                {role}
              </Checkbox>
            </Box>
          ))}
        </Flex>
      )}
    </UiModal>
  );
};

export default EditRoles;
