const Menu = {
  baseStyle: {
    item: {
      px: null,
      py: null,
      _focus: {
        bg: 'blue-lighter',
      },
      _active: {
        bg: 'primary-lighter',
      },
    },
  },
};

export default Menu;
