const Button = {
  // 1. We can update the base styles
  baseStyle: {
    borderRadius: '6px',
    lineHeight: '1em',
    fontWeight: 400,
    border: '1px solid',
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    large: {
      fontSize: 16,
      lineHeight: '19px',
      py: '12px',
      px: '20px',
    },
    medium: {
      fontSize: 14,
      lineHeight: '17px',
      py: '10px',
      px: '16px',
    },
    small: {
      fontSize: 13,
      lineHeight: '15px',
      py: '9px',
      px: '14px',
    },
    smallest: {
      fontSize: 12,
      lineHeight: '13px',
      py: '7px',
      px: '14px',
    },
  },
  defaultProps: {
    size: 'small',
  },
};

export default Button;
