import React, { useCallback, useState } from 'react';
import { Menu, MenuList, MenuItem, MenuButtonProps, Checkbox, Box } from '@chakra-ui/react';

import { CancelButtonFilter, OpenButtonFilter, FilterHeader } from '@/components/column-filters';

type Option = {
  label: string;
  value: string;
};

type UiSelectMenuProps = {
  options: Option[];
  onChange: (selectedValues: string[]) => void;
  buttonProps?: MenuButtonProps;
  defaultValues?: string[];
};

const UiSelectFilter = ({ options, buttonProps, onChange, defaultValues }: UiSelectMenuProps): JSX.Element => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(defaultValues || []);

  const onClose = useCallback(() => {
    setSelectedOptions([]);
    onChange([]);
  }, [onChange]);

  const onSelect = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const _selectedOptions = [...selectedOptions];
    const value = e.currentTarget.value;
    const index = selectedOptions.findIndex(option => option === value);

    if (index === -1) {
      _selectedOptions.push(value);
    } else {
      _selectedOptions.splice(index, 1);
    }

    setSelectedOptions(_selectedOptions);
    onChange(_selectedOptions);
  };

  const onToggleAll = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
      onChange([]);
    } else {
      const values = options.map(option => option.value);
      setSelectedOptions(values);
      onChange(values);
    }
  };

  const color = selectedOptions.length ? 'primary' : 'grey';

  return (
    <Menu isLazy closeOnSelect={false} placement="bottom-end">
      {({ isOpen }) => (
        <>
          {selectedOptions.length && !isOpen ? <CancelButtonFilter onClickCross={onClose} /> : null}

          <OpenButtonFilter color={color} buttonProps={buttonProps} />

          <MenuList width="350px">
            <FilterHeader onClickClear={onClose} />

            <Box maxHeight="300px" overflowY="auto">
              <MenuItem
                py="2"
                px="3"
                value=""
                icon={
                  <Checkbox
                    size="small"
                    pr="1"
                    isChecked={selectedOptions.length === options.length}
                    onChange={onToggleAll}
                  />
                }
                onClick={onToggleAll}
              >
                (Select all)
              </MenuItem>

              {options.map((option, index) => (
                <MenuItem
                  py="2"
                  px="3"
                  key={`${option.value}-${index}`}
                  icon={
                    <Checkbox
                      size="small"
                      pr="1"
                      isChecked={selectedOptions.includes(option.value)}
                      onChange={onSelect}
                    />
                  }
                  value={option.value}
                  onClick={onSelect}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default UiSelectFilter;
