import React from 'react';
import { MenuButton, MenuButtonProps } from '@chakra-ui/react';

import { UiIcon } from '@/components/ui-components';

type OpenButtonProps = {
  color: string;
  buttonProps?: MenuButtonProps;
};

const OpenButtonFilter = ({ color, buttonProps }: OpenButtonProps) => (
  <MenuButton
    type="button"
    borderRadius="50%"
    color={color}
    bg={'transparent'}
    p={1}
    w="24px"
    h="24px"
    lineHeight="1"
    _focus={{
      outline: 'none',
      bg: `${color}-lighter`,
    }}
    _hover={{
      bg: `${color}-lighter`,
    }}
    _active={{
      bg: `${color}-lightest`,
    }}
    {...buttonProps}
  >
    <UiIcon name="filter" />
  </MenuButton>
);

export default OpenButtonFilter;
