const icons = [
  {
    displayName: 'chevron-down',
    viewBox: '0 0 24 24',
    path: [
      <path fill="none" d="M0 0h24v24H0z"></path>,
      <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>,
    ],
  },
  {
    displayName: 'chevron-up',
    viewBox: '0 0 24 24',
    path: [
      <path fill="none" d="M0 0h24v24H0z"></path>,
      <path fill="currentColor" d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"></path>,
    ],
  },
  {
    displayName: 'chevron-left',
    viewBox: '0 0 24 24',
    path: [
      <path fill="none" d="M0 0h24v24H0z"></path>,
      <path fill="currentColor" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>,
    ],
  },
  {
    displayName: 'filter',
    viewBox: '0 0 24 24',
    path: [
      <path fill="none" d="M0,0h24 M24,24H0"></path>,
      <path
        fill="currentColor"
        d="M4.25,5.61C6.27,8.2,10,13,10,13v6c0,0.55,0.45,1,1,1h2c0.55,0,1-0.45,1-1v-6c0,0,3.72-4.8,5.74-7.39 C20.25,4.95,19.78,4,18.95,4H5.04C4.21,4,3.74,4.95,4.25,5.61z"
      ></path>,
      <path fill="none" d="M0,0h24v24H0V0z"></path>,
    ],
  },
  {
    displayName: 'cross',
    viewBox: '0 0 16 16',
    path: (
      <g fill="transparent" stroke="currentColor">
        <line x1="12" y1="4" x2="4" y2="12" strokeWidth="2"></line>
        <line x1="4" y1="4" x2="12" y2="12" strokeWidth="2"></line>
      </g>
    ),
  },
  {
    displayName: 'arrow-down',
    viewBox: '0 0 16 16',
    path: (
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.207 11.275V3h1v8.275l3.5-3.5.707.707-4.707 4.707L3 8.482l.707-.707 3.5 3.5z"
      ></path>
    ),
  },
  {
    displayName: 'edit',
    viewBox: '0 0 16 16',
    path: [
      <path fill="currentColor" d="M1 9.58v2.547h2.398l9.64-9.732L10.52 0 8.28 2.254z"></path>,
      <path fill="currentColor" d="M9 2l1.938 2"></path>,
      <rect fill="currentColor" stroke="none" width="14" height="14" y="14" rx="0.5"></rect>,
    ],
  },
  {
    displayName: 'user',
    viewBox: '0 0 16 16',
    path: (
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9.00012C5.79086 9.00012 4 7.20926 4 5.00012C4 2.79098 5.79086 1.00012 8 1.00012C10.2091 1.00012 12 2.79098 12 5.00012C12 7.20926 10.2091 9.00012 8 9.00012ZM8 8.00012C9.65685 8.00012 11 6.65698 11 5.00012C11 3.34327 9.65685 2.00012 8 2.00012C6.34315 2.00012 5 3.34327 5 5.00012C5 6.65698 6.34315 8.00012 8 8.00012ZM15.5 15.4501V16.0001H0.5V15.4501C0.5 12.6338 3.89446 10.5001 8 10.5001C12.1055 10.5001 15.5 12.6338 15.5 15.4501ZM8 11.5001C4.70303 11.5001 2.02837 13.0835 1.56957 15.0001H14.4304C13.9716 13.0835 11.297 11.5001 8 11.5001Z"
      ></path>
    ),
  },
  {
    displayName: 'search',
    viewBox: '0 0 24 24',
    path: (
      <path
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      ></path>
    ),
  },
  {
    displayName: 'access-all',
    viewBox: '0 0 15 16',
    path: [
      <g fill="#153B5D" transform="translate(7 2)">
        <circle cx="4.091" cy="2.273" r="2.273"></circle>
        <path d="M8.586 10c0-2.51-1.922-4.545-4.293-4.545C1.922 5.455 0 7.49 0 10h8.586z"></path>
      </g>,
      <g fill="#4093FF" transform="translate(0 2)">
        <circle cx="5" cy="3" r="3"></circle>
        <path d="M10 12a5 5 0 10-10 0h10z"></path>
      </g>,
    ],
  },
  {
    displayName: 'check',
    viewBox: '0 0 16 16',
    path: <path fill="currentColor" d="M5.09 11.636L1.274 7.818 0 9.091l5.09 5.09L16 3.274 14.727 2z"></path>,
  },
  {
    displayName: 'card',
    viewBox: '0 -2 17 17',
    path: [
      <path
        fill="currentColor"
        d="M2 .955c-.552 0-1 .427-1 .954v8.182c0 .527.448.954 1 .954h12c.552 0 1-.427 1-.954V1.909c0-.527-.448-.954-1-.954H2zM2 0h12c1.105 0 2 .855 2 1.91v8.18c0 1.055-.895 1.91-2 1.91H2c-1.105 0-2-.855-2-1.91V1.91C0 .854.895 0 2 0z"
      ></path>,
      <path fill="currentColor" d="M8 4.5v-1h5.714v1zm0 2.286v-1h5.714v1zM2.857 9.071v-1h10.857v1z"></path>,
      <circle fill="currentColor" cx={4} cy={5.143} r={1.714}></circle>,
    ],
  },
  {
    displayName: 'view-solid',
    viewBox: '0 0 16 16',
    path: [
      <path
        fill="currentColor"
        stroke="none"
        d="M.5 8s3.358 5 7.5 5c4.142 0 7.5-5 7.5-5S12.142 3 8 3C3.858 3 .5 8 .5 8z"
      ></path>,
      <path fill="#fff" stroke="transparent" d="M8 10a2 2 0 110-4 2 2 0 010 4z"></path>,
    ],
  },
  {
    displayName: 'organization',
    viewBox: '0 -1 15 18',
    path: [
      <path
        fill="transparent"
        stroke="currentColor"
        d="M2.12 15.4H12.6V1a.5.5 0 00-.5-.5H2.62a.5.5 0 00-.5.5v14.4z"
      ></path>,
      <path fill="transparent" stroke="currentColor" strokeLinecap="round" d="M1.155 15.54h12.41"></path>,
      <path
        fill="transparent"
        stroke="currentColor"
        d="M3.792 3.568h2.793M3.792 6.36h2.793m-2.793 5.585h2.793M3.792 9.153h2.793m1.55-5.585h2.793M8.136 6.36h2.792m-2.792 5.585h2.792M8.136 9.153h2.792"
      ></path>,
    ],
  },
  {
    displayName: 'home',
    viewBox: '0 0 16 16',
    path: (
      <path
        fill="currentColor"
        d="M14 7.6V14a1 1 0 01-1 1h-3a1 1 0 01-1-1v-2.9a.1.1 0 00-.1-.1H7.1a.1.1 0 00-.1.1V14a1 1 0 01-1 1H3a1 1 0 01-1-1V7.6l-1.223.816A.5.5 0 010 8V5.682a.54.54 0 01.008-.091.5.5 0 01.215-.507L7.445.269a1 1 0 011.11 0l7.222 4.815a.5.5 0 01.215.507c.005.03.008.06.008.09V8a.5.5 0 01-.777.416L14 7.601zM4.061 5.026L7.454 2.75a1.18 1.18 0 01.12-.067l.149-.099a.5.5 0 01.554 0l.15.1c.04.019.08.04.119.066l3.393 2.275L15 7.065V5.769L8 1.1 1 5.768v1.298l3.061-2.041zM13 6.949L8 3.601 3 6.949V14h3v-2.9A1.1 1.1 0 017.1 10h1.8a1.1 1.1 0 011.1 1.1V14h3V6.95z"
      ></path>
    ),
  },
  {
    displayName: 'home-solid',
    viewBox: '0 0 16 16',
    path: (
      <path
        fill="currentColor"
        d="M13.555297 9.41456825v4.30107945c0 .399193-.2972527.7203483-.6667349.7203483H9.66600975c-.18335211 0-.33336748-.1620784-.33336748-.3601741v-3.3616253c0-.1980958-.15001536-.3601741-.33336748-.3601741H6.99906992c-.18335211 0-.33336748.1620783-.33336748.3601741v3.3616253c0 .1980957-.15001536.3601741-.33336748.3601741h-3.2225523c-.36948228 0-.66673495-.3211553-.66673495-.7203483V9.41456825c0-.10805224.04444899-.21010158.12223474-.27913495l5.22275717-4.64624636c.12223474-.10805224.30003073-.10805224.42504354 0L13.4358403 9.1354333c.0750077.06903337.1194567.17108271.1194567.27913495zm2.3224601-1.82788374L13.555297 5.51868468V1.36167486c0-.19809577-.1500154-.36017413-.3333675-.36017413h-1.5557149c-.1833521 0-.3333675.16207836-.3333675.36017413v2.17905352L8.84648137 1.3286589c-.49171703-.43821187-1.20290099-.43821187-1.69461802 0L.1205876 7.58668451c-.14168118.12606095-.16112761.35417124-.044449.50724524l.7084059.93044986c.11667862.153074.32781135.17408416.46949253.04802321l6.53400259-5.81381084c.12223474-.10805224.30003073-.10805224.42504354 0l6.53400254 5.81381084c.1416812.12606095.352814.10505079.4694926-.04802321l.7084059-.93044986c.1166786-.15607545.0944541-.38118429-.0472271-.50724524z"
      ></path>
    ),
  },
  {
    displayName: 'storage',
    viewBox: '0 0 16 17',
    path: [
      <path
        fill="currentColor"
        d="M6.135 2.81a6.137 6.137 0 001 12.19 6.137 6.137 0 006.054-5.135H6.135V2.81zm1-1.08v7.135h7.135A7.135 7.135 0 117.135 1.73z"
      ></path>,
      <path
        fill="currentColor"
        d="M10 1.082v5.054h5.054A6.14 6.14 0 0010 1.082zm6.135 6.054H9V.001a7.135 7.135 0 017.135 7.135z"
      ></path>,
    ],
  },
  {
    displayName: 'info-circle',
    viewBox: '0 0 16 16',
    path: [
      <path
        fill="currentColor"
        clipRule="evenodd"
        fillRule="evenodd"
        d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
      ></path>,
      <path
        fill="currentColor"
        d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z"
      ></path>,
      <circle fill="currentColor" cx="8" cy="4.5" r="1"></circle>,
    ],
  },
  {
    displayName: 'settings',
    viewBox: '0 0 16 16',
    path: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M0.5 4H3.05001C3.28164 2.85888 4.29052 2 5.5 2C6.70948 2 7.71836 2.85888 7.94999 4H15.5C15.7761 4 16 4.22386 16 4.5C16 4.77614 15.7761 5 15.5 5H7.94999C7.71836 6.14112 6.70948 7 5.5 7C4.29052 7 3.28164 6.14112 3.05001 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4ZM4.07881 4.01891L4.08535 4C4.29127 3.4174 4.84689 3 5.5 3C6.15311 3 6.70873 3.4174 6.91465 4C6.96992 4.15639 7 4.32468 7 4.5C7 4.66827 6.97229 4.83008 6.92119 4.98109L6.91465 5C6.70873 5.5826 6.15311 6 5.5 6C4.84689 6 4.29127 5.5826 4.08535 5C4.03008 4.84361 4 4.67532 4 4.5C4 4.33173 4.02771 4.16992 4.07881 4.01891ZM8.05001 11H0.5C0.223858 11 0 11.2239 0 11.5C0 11.7761 0.223858 12 0.5 12H8.05001C8.28164 13.1411 9.29052 14 10.5 14C11.7095 14 12.7184 13.1411 12.95 12H15.5C15.7761 12 16 11.7761 16 11.5C16 11.2239 15.7761 11 15.5 11H12.95C12.7184 9.85888 11.7095 9 10.5 9C9.29052 9 8.28164 9.85888 8.05001 11ZM11.9146 11C11.7087 10.4174 11.1531 10 10.5 10C9.84689 10 9.29127 10.4174 9.08535 11C9.08313 11.0063 9.08095 11.0126 9.07881 11.0189C9.02771 11.1699 9 11.3317 9 11.5C9 11.6753 9.03008 11.8436 9.08535 12C9.29127 12.5826 9.84689 13 10.5 13C11.1531 13 11.7087 12.5826 11.9146 12C11.9169 11.9937 11.919 11.9874 11.9212 11.9811C11.9723 11.8301 12 11.6683 12 11.5C12 11.3247 11.9699 11.1564 11.9146 11Z"
      ></path>
    ),
  },
];

export default icons;
