import React from 'react';
import { ColumnInstance } from 'react-table';
import { Menu, MenuList, MenuItem, MenuButtonProps, Checkbox, Box, MenuDivider } from '@chakra-ui/react';

import { OpenColumnToggle } from '@/components/column-filters';

type UiColumnToggleProps = {
  options: (ColumnInstance<Column> & { disableToggle?: boolean; name?: string })[];
  buttonProps?: MenuButtonProps;
};

const UiColumnToggle = ({ options, buttonProps }: UiColumnToggleProps): JSX.Element => {
  const availableColumns = options.filter(options => !options.disableToggle);

  const checkedColumns = availableColumns.filter(col => col.getToggleHiddenProps().checked);

  return availableColumns.length ? (
    <Menu isLazy closeOnSelect={false} placement="bottom-end">
      <OpenColumnToggle color={'grey'} buttonProps={buttonProps} />

      <MenuList width="350px">
        <Box maxHeight="300px" overflowY="auto">
          <Box py="2" px="3">
            Toggle column visibility
          </Box>
          <MenuDivider color="grey-light" />

          {availableColumns.map(option => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox
                py="2"
                px="3"
                size="small"
                w="full"
                isChecked={option.getToggleHiddenProps().checked}
                isDisabled={!option.getToggleHiddenProps().checked && checkedColumns.length > 6}
                {...option.getToggleHiddenProps()}
              >
                {option.name || option.id}
              </Checkbox>
            </MenuItem>
          ))}
        </Box>
      </MenuList>
    </Menu>
  ) : (
    <></>
  );
};

export default UiColumnToggle;
