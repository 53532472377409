import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import Sidebar, { appLinks } from '@/components/sidebar';
import { UiIcon } from './components/ui-components';

const routes = [
  {
    name: 'home',
    index: true,
    element: <Navigate to="/environments" />,
  },
  ...appLinks.map(link => {
    const LinkElement = link.element;

    return {
      name: link.name,
      path: link.path,
      element: <LinkElement />,
    };
  }),
];

const Routes = () => (
  <Flex minH="screenH" overflowX="hidden" position="relative">
    <Flex
      position="absolute"
      top="0"
      left="0"
      right="0"
      h="50px"
      w="full"
      borderBottom="default"
      borderColor="grey"
      py="1"
      color="grey-darker"
      bg="white"
      wrap="wrap"
    >
      <Flex fontSize="2xl" fontWeight="medium" align="center" px="4" w="250px">
        <span>Dome</span>
      </Flex>
    </Flex>

    <Box bg="blue-darker" w="250px" pt="50px">
      <Sidebar />
    </Box>
    <Box mt="50px" pt="5" mx="auto" position="relative" w="calc(100% - 250px)">
      <Box mx="5" overflowX="hidden">
        {useRoutes(routes)}
      </Box>
    </Box>
  </Flex>
);

export default Routes;
