import React from 'react';
import { createIcon, IconProps } from '@chakra-ui/react';

import icons from './icons';

type UiIconProps = IconProps & {
  name: IconName;
};

const UiIcon = ({ name, ...restProps }: UiIconProps) => {
  const _icon = icons.find(icon => icon.displayName === name);

  if (!_icon) return null;

  const Icon = createIcon(_icon);

  return <Icon w="16px" h="16px" {...restProps} />;
};

export default UiIcon;
