import Button from './button';
import Alert from './alert';
import Modal from './modal';
import Checkbox from './checkbox';
import Menu from './menu';
import Input from './input';

const components = {
  Button,
  Alert,
  Modal,
  Checkbox,
  Menu,
  Input,
};

export default components;
