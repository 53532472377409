const general = {
  config: {
    cssVarPrefix: 'bricsys',
  },
  styles: {
    global: {
      html: {
        fontSize: '62.5%',
      },
      body: {
        fontFamily:
          "'Roboto', sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        fontSize: 'md',
        fontWeight: 400,
        fontStyle: 'normal',
        WebkitTextSizeAdjust: '100%',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',
        textRendering: 'optimizeLegibility',
        fontVariantNumeric: 'proportional-nums',
        overflowX: 'auto',
        overflowY: 'scroll',
      },

      '::-webkit-scrollbar': {
        width: '7px',
        height: '7px',
      },
      'html > ::-webkit-scrollbar': {
        width: '0',
      },
      '::-webkit-scrollbar-track': {
        backgroundColor: '#f7f7f7',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#d5d5d5',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#c6c5c5',
      },
      'input::-ms-clear': {
        display: 'none',
      },
    },
  },
};

export default general;
