import React, { useMemo } from 'react';
import { ColumnWithStrictAccessor, UseFiltersOptions, UseSortByOptions } from 'react-table';
import { Box, Flex } from '@chakra-ui/react';

import { useSuperUserRolesQuery } from '@/store/general';

import { SearchColumnFilter } from '@/components/column-filters';
import { UiIcon } from '@/components/ui-components';

import Table from '@/components/Table';

const SuperUserRoles = () => {
  const { data: roles, isLoading } = useSuperUserRolesQuery();

  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const columns: (ColumnWithStrictAccessor<Column> & UseFiltersOptions<Column> & UseSortByOptions<Column>)[] = useMemo(
    () => [
      {
        Header: <Box pl="5">Role</Box>,
        accessor: 'name',
        width: 1,
        disableToggle: true,
        Filter: SearchColumnFilter,
        Cell: ({ cell: { value } }) => (
          <Flex align="center">
            <UiIcon name="card" color="grey-darker" mr="2" />
            {value}
          </Flex>
        ),
      },
      {
        Header: 'Description',
        accessor: 'desciption',
        name: 'Description',
        width: 2,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => <div>{value || '-'}</div>,
      },
    ],
    []
  );

  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const content = useMemo(
    () =>
      roles?.map(role => ({
        name: role,
        description: '',
      })) || [],
    [roles]
  );

  // react-table requires initialState.sortBy and initialState.filters to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useSortBy#table-options
  // https://react-table-v7.tanstack.com/docs/api/useFilters#table-options
  const options = {
    initialState: {
      sortBy: useMemo(
        () => [
          {
            id: 'name',
            desc: false,
          },
        ],
        []
      ),
      filters: useMemo(() => [], []),
    },
    defaultColumn: {
      disableFilters: false,
    },
    disableSortBy: false,
  };

  return (
    <Box maxW="full">
      <Box fontSize="2xl" fontWeight="medium" mb="5">
        Roles
      </Box>

      <Table
        isLoading={isLoading}
        data={content}
        columns={columns}
        options={options}
        placeholderText="No roles found"
      />
    </Box>
  );
};

export default SuperUserRoles;
