import SuperUsers from '@/pages/super-users';

import EnvironmentList from '@/pages/EnvironmentList';
import EnvironmentDetails from '@/pages/EnvironmentDetails';
import Features from '@/pages/Features';
import SuperUserRoles from '@/pages/SuperUserRoles';

type AppLink = {
  path: string;
  name: string;
  icon?: IconName;
  element: () => JSX.Element;
  isMenuItem?: boolean;
};

const appLinks: AppLink[] = [
  {
    path: '/environments',
    name: 'Environments',
    element: () => <EnvironmentList />,
    icon: 'home',
    isMenuItem: true,
  },
  {
    path: '/environments/:envID',
    name: 'Environment Details',
    element: () => <EnvironmentDetails />,
  },
  {
    path: '/features',
    name: 'Features',
    icon: 'storage',
    element: () => <Features />,
    isMenuItem: true,
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'user',
    element: () => <SuperUsers />,
    isMenuItem: true,
  },
  {
    path: '/roles',
    name: 'Roles',
    icon: 'card',
    element: () => <SuperUserRoles />,
    isMenuItem: true,
  },
];

export default appLinks;
