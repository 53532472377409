const shadows = Object.freeze({
  sm: '0 0 5px 0 rgba(0, 0, 0, 0.05)',
  default: '0 2px 4px 0 rgba(0,0,0,0.10)',
  md: '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
  lg: '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)',
  xl: '0 0 24 px 0 rgba(0, 0, 0, 0.22), 0 24 px 24 px 0 rgba(0, 0, 0, 0.3)',
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  outline: '0 0 0 3px rgba(52,144,220,0.5)',
  card: '0 0 5px 0 rgba(0, 0, 0, 0.12)',
  button: '0 1px 5px 0 rgba(50, 50, 50, 0.1)',
  menu: '0 1px 8px 0 rgba(50,50,50,0.21)',
  dropdown: 'rgba(0, 0, 0, 0.25) 0px 1px 4px',
  'button-pressed':
    '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
  dialog: '0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12)',
  'menu-lg': '0 1px 5px rgba(0,0,0,0.2), 0 2px 2px rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12)',
  none: 'none',
  'shadow-button':
    '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
});

export default shadows;
