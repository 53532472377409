import axios, { AxiosResponse, AxiosRequestConfig } from "axios";

const BASE_URL = `${window.config.DOME_BACKEND_ENDPOINT}`;

const isStatusValid = (response: AxiosResponse | number) => {
  const responseCode =
    typeof response === "number" ? response : response.status;
  return responseCode >= 200 && responseCode < 300;
};

const config: AxiosRequestConfig = {
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: isStatusValid,
};

export const axiosInstance = axios.create(config);
