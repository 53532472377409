import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getCookie } from 'typescript-cookie';

const token = () => getCookie('access_token');

type EnvResult = {
  result: {
    data: Record<string, string | Record<string, string | Record<string, string>>>;
    environmentId: string;
  };
};

type SuperUsersList = {
  users: SuperUser[];
};

type Feature = {
  name: string;
  value: string;
  possibleValues: string[];
};

export const general = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: window.config.DOME_SERVER_URL,
    prepareHeaders: headers => {
      token() && headers.set('Authorization', `Bearer ${token()}`);
      return headers;
    },
  }),
  tagTypes: ['Features', 'Users'],
  endpoints: builder => ({
    environments: builder.query<Environment[], void>({
      query: () => ({
        url: '/environments',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token(),
        },
      }),
      transformResponse: (res: { list: Environment[] }) => res.list,
    }),
    envByID: builder.query<EnvResult, { envID: string }>({
      query: ({ envID }) => ({
        url: `/environments/${envID}`,
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token(),
        },
      }),
    }),
    superUsers: builder.query<SuperUser[], void>({
      query: () => ({
        url: '/super-users',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token(),
        },
      }),
      providesTags: ['Users'],
      transformResponse: (res: { users: SuperUser[] }) => res.users,
    }),
    updateUserRoles: builder.mutation<SuperUsersList, { keycloakId: string; roles: Role[] }>({
      query: ({ keycloakId, roles }) => ({
        url: `/super-users/${keycloakId}/roles`,
        method: 'PUT',
        body: {
          roles,
        },
        headers: {
          Authorization: 'Bearer ' + token(),
        },
      }),
      invalidatesTags: ['Users'],
    }),
    superUserRoles: builder.query<Role[], void>({
      query: () => ({
        url: '/super-user-roles',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token(),
        },
      }),
      transformResponse: (res: { roles: Role[] }) => res.roles,
    }),
    features: builder.query<FeatureList, void>({
      query: () => ({
        url: '/features',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token(),
        },
      }),
      providesTags: ['Features'],
      transformResponse: (res: { list: { data: { features: Feature[] }; environmentId: string }[] }) => {
        const featureList: FeatureList = {};

        res.list.forEach(item =>
          item.data.features.forEach(feature => {
            featureList[feature.name] = {
              ...featureList[feature.name],
              [item.environmentId]: { value: feature.value, possibleValues: feature.possibleValues },
            };
          })
        );

        return featureList;
      },
    }),
    updateFeatures: builder.mutation<FeatureList, UpdateEnvironment>({
      query: updates => ({
        url: '/features/bulk/set',
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token(),
        },
        body: updates,
      }),
      invalidatesTags: ['Features'],
    }),
  }),
});

export const {
  useEnvironmentsQuery,
  useEnvByIDQuery,
  useSuperUsersQuery,
  useSuperUserRolesQuery,
  useFeaturesQuery,
  useUpdateFeaturesMutation,
  useUpdateUserRolesMutation,
} = general;
