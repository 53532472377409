import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Link, Flex } from '@chakra-ui/react';

import { UiIcon } from '@/components/ui-components';

import appLinks from './appLinks';

const Sidebar = () => (
  <Box color="grey">
    {appLinks
      .filter(link => link.isMenuItem)
      .map(({ path, name, icon }, index) => (
        <Link
          as={NavLink}
          display="block"
          py="2"
          px="4"
          position="relative"
          key={`appLink-${index}`}
          to={path}
          _hover={{ color: 'white', bg: 'blue-selected-dark' }}
          _focus={{ color: 'white', bg: 'blue-selected-dark' }}
          transition="ease-in-out"
          transitionProperty="all"
          transitionDuration="0.15s"
          _activeLink={{
            color: 'white',

            _before: {
              opacity: 1,
            },
          }}
          _before={{
            content: `''`,
            display: 'block',
            position: 'absolute',
            left: '0',
            top: '0',
            height: 'half',
            my: 'auto',
            borderTopRightRadius: 'lg',
            borderBottomRightRadius: 'lg',
            bottom: '0',
            borderLeft: '3',
            borderColor: 'white',
            opacity: '0',
            transition: 'opacity 0.15s ease-in-out',
          }}
        >
          <Flex h="6" align="center">
            {icon ? <UiIcon mr="3" name={icon} /> : null} {name}
          </Flex>
        </Link>
      ))}
  </Box>
);

export { appLinks };

export default Sidebar;
