const Input = {
  baseStyle: {
    field: {
      color: 'grey-darker',
      outline: 'none',
      border: '1px solid',
      borderColor: 'grey',
      _active: {
        borderColor: 'primary',
      },
      _focus: {
        borderColor: 'primary',
      },
      _invalid: {
        borderColor: 'red',
      },
      _disabled: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },

      '&::placeholder': {
        color: 'grey-dark',
      },
    },
  },
  sizes: {
    smallest: {
      field: {
        fontSize: '12px',
        lineHeight: '14px',
        px: '14px',
        py: '10px',
        height: '36px',
      },
    },
    small: {
      field: {
        fontSize: '13px',
        lineHeight: '16px',
        px: '15px',
        py: '12px',
        height: '42px',
      },
    },
    medium: {
      field: {
        fontSize: '14px',
        lineHeight: '20px',
        px: '16px',
        py: '14px',
        height: '50px',
      },
    },
    large: {
      field: {
        fontSize: '16px',
        lineHeight: '22px',
        px: '18px',
        py: '16px',
        height: '60px',
      },
    },
  },
  variants: {
    rounded: {
      field: {
        borderRadius: 'full',
      },
    },
    default: {
      field: {
        borderRadius: 'md',
      },
    },
    square: {
      field: {
        borderRadius: 'none',
      },
    },
  },
  defaultProps: {
    size: 'smallest',
    variant: 'default',
  },
};

export default Input;
