import React from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, Box, Flex, Divider } from '@chakra-ui/react';

import { useEnvByIDQuery } from '@/store/general';

import { NoActivity } from '@/components/ui-placeholder';

type EntryData = { data: Record<string, string | Record<string, string | Record<string, string>>> };

const Entries = ({ data }: EntryData) => (
  <Box>
    {Object.entries(data).map(([key, value], index) => (
      <Box key={`entry-${index}`}>
        {typeof value === 'string' ? (
          <Flex wrap="wrap" my="1">
            <Box w="200px" fontWeight="medium" color="grey-dark">
              {key}
            </Box>
            <Box color="grey-dark">{value}</Box>
          </Flex>
        ) : (
          <Box mb="5">
            {index ? <Divider mb="5" color="grey" /> : null}

            <Box fontSize="lg" fontWeight="medium" mb="3">
              {key}
            </Box>
            <Box px="5">
              <Entries data={value} />
            </Box>
          </Box>
        )}
      </Box>
    ))}
  </Box>
);

const EnvironmentDetails = () => {
  const { envID } = useParams();
  const { data, isLoading } = useEnvByIDQuery({ envID: envID as string });

  return (
    <>
      <Box fontSize="2xl" fontWeight="medium" mb="5">
        Environment details
      </Box>
      {isLoading ? (
        <Spinner
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          m="auto"
          width="50px"
          height="50px"
          color="primary"
          thickness="4px"
          speed="0.75s"
        />
      ) : Object.keys(data?.result.data || {}).length ? (
        <Entries data={data?.result.data || {}} />
      ) : (
        <Flex
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          direction="column"
          align="center"
          justify="center"
        >
          <NoActivity />
          <Box mt="4" textAlign="center" fontWeight="medium" fontSize="md">
            no environment with this id found: {envID}
          </Box>
        </Flex>
      )}
    </>
  );
};

export default EnvironmentDetails;
