import React, { useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ModalProps,
} from '@chakra-ui/react';

import { UiButton, UiIcon } from '@/components/ui-components';

type UiModalProps = ModalProps & {
  isOpen: boolean;
  title?: string;
  isLoading?: boolean;
  buttonDisabled?: boolean;
  confirmOnly?: boolean;
  height?: number;
  onSave?: () => void;
  onClose: () => void;
};

const UiModal = ({
  isOpen,
  isLoading,
  buttonDisabled,
  confirmOnly,
  onClose,
  onSave,
  children,
  title,
  height,
  ...restProps
}: UiModalProps) => {
  const onCloseModal = useCallback(() => onClose(), [onClose]);
  const onSaveModal = useCallback(() => (onSave ? onSave() : null), [onSave]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      {...restProps}
    >
      <ModalOverlay />
      <ModalContent height={height}>
        <ModalHeader px="5" py="4" display="flex" justifyContent="space-between" alignItems="center">
          {title}

          <ModalCloseButton width="36px" height="36px">
            <UiIcon name="cross" />
          </ModalCloseButton>
        </ModalHeader>

        <ModalBody px="5" py="2">
          {children}
        </ModalBody>

        <ModalFooter px="5" py="4">
          {!confirmOnly ? (
            <UiButton ghost onClick={onCloseModal}>
              Cancel
            </UiButton>
          ) : null}

          <UiButton ml="2" onClick={onSaveModal} isLoading={isLoading} disabled={buttonDisabled}>
            Save
          </UiButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UiModal;
