import rgba from '@hexagon-geo/js-utils/lib/rgba';

const primary = '#4093ff';
const secondary = '#4d4d4d';
const warning = '#ffc82e';
const success = '#25c760';
const error = '#ff4c29';
const light = '#f7f7f7';
const grey = '#999999';
const dark = '#343a40';

const OUTLINE_ALPHA = '0.25';
const PING_ALPHA = '0.4';

const colors: Readonly<{ [key: string]: string }> = Object.freeze({
  primary: primary,
  'primary-active': '#0d76ff',
  'primary-hover': '#2685ff',
  'primary-light': '#73b0ff',
  'primary-lighter': '#d9e9ff',
  'primary-lightest': '#f1f5ff',
  'primary-outline': rgba(primary, OUTLINE_ALPHA),
  'primary-ping': rgba(primary, PING_ALPHA),

  tertiary: '#f1f5ff',
  'tertiary-active': '#bed1ff',
  'tertiary-hover': '#d7e3ff',
  'tertiary-light': '#f2f5fe',

  secondary,
  'secondary-active': '#494f54',
  'secondary-hover': '#545b62',
  'secondary-light': '#868e96',
  'secondary-lighter': '#afb5ba',
  'secondary-lightest': '#f6f4f9',
  'secondary-darker': '#3d4246',
  'secondary-outline': rgba(secondary, OUTLINE_ALPHA),
  'secondary-ping': rgba(secondary, PING_ALPHA),

  warning: warning,
  'warning-active': '#dca200',
  'warning-hover': '#f5b500',
  'warning-light': '#ffcd43',
  'warning-lighter': '#ffe8a9',
  'warning-lightest': '#ffefc2',
  'warning-outline': rgba(warning, OUTLINE_ALPHA),
  'warning-ping': rgba(warning, PING_ALPHA),

  error: error,
  'error-active': '#fc2800',
  'error-hover': '#ff3c16',
  'error-light': '#ff7c63',
  'error-lighter': '#ffd2c9',
  'error-lightest': '#ffe7e2',
  'error-outline': rgba(error, OUTLINE_ALPHA),
  'error-ping': rgba(error, PING_ALPHA),

  success: success,
  'success-active': '#1d9c56',
  'success-hover': '#21b162',
  'success-light': '#43dc88',
  'success-lighter': '#99ecbe',
  'success-lightest': '#c4f4d9',
  'success-outline': rgba(success, OUTLINE_ALPHA),
  'success-ping': rgba(success, PING_ALPHA),

  dark,
  'dark-active': '#1d2124',
  'dark-hover': '#23272b',
  'dark-light': '#343a40',
  'dark-lighter': '#7a8693',
  'dark-lightest': '#b2b9c1',
  'dark-outline': 'rgba(82,88,93,.5)',
  'dark-ping': rgba(dark, PING_ALPHA),

  light: light,
  'light-active': '#dddddd',
  'light-hover': '#eaeaea',
  'light-outline': rgba(light, OUTLINE_ALPHA),
  'light-ping': rgba(grey, PING_ALPHA),

  transparent: 'transparent',
  inherit: 'inherit',
  black: '#000000',
  white: '#ffffff',

  grey: '#cbcbcb', // light text, ...
  'grey-darkest': '#222222', // primary text color, ...
  'grey-darker': '#444444', // secondary text color, disabled dark text color ...
  'grey-dark': '#999', // lighter text, #999999, ...
  'grey-light': '#dddddd', // dividers, borders, ...
  'grey-lighter': '#f2f2f2', // bg for inputs, #efefef...
  'grey-lightest': '#f7f7f7', // main background color, ...
  'grey-cold': '#919cab',
  'grey-cold-light': '#aebbce', // menu text color, menu icons, ...

  blue: '#4093ff',
  'blue-darkest': '#00263d', // tooltip bg, infobar bg
  'blue-darker': '#143b5d', // sidebar bg, ...
  'blue-dark': '#2681f6',
  'blue-light': '#ddefff', // 1 usecase: .storage__usage in AdminProjectList
  'blue-lighter': '#f3f6ff',
  'blue-lightest': '#f8faff',
  'blue-selected-dark': '#457ec7', //used in dashboard
  'blue-selected': '#f1f5ff',
  'blue-bricsys': '#4093ff',
  'blue-card-border': '#bedaff',

  green: '#25c760',
  orange: '#ffae00',
  'orange-dark': '#ff6200',
  'orange-light': '#fff6db', //used in workflow task history warning messages
  red: '#e74610',
  'red-notification': '#FF4747',
  yellow: '#ffc82e',

  binder: '#b0f9d6',
  revision: '#b0f9d6',
  annotation: '#ffbcc2',
  note: '#fff576',
  notification: '#ccecff',
  workflow: '#c6bfff',
  share: '#4093ff',
  link: '#dcdcdc',

  overlay: 'rgba(215, 215, 215, 0.4)',
  'overlay-light': 'rgba(215, 215, 215, 0.7)',
});

export default colors;
