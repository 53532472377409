import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { ColumnWithStrictAccessor, UseFiltersOptions } from 'react-table';
import { Box, Link, Flex } from '@chakra-ui/react';

import { useEnvironmentsQuery } from '@/store/general';

import { SearchColumnFilter } from '@/components/column-filters';
import { UiIcon } from '@/components/ui-components';
import { NoActivity } from '@/components/ui-placeholder';

import Table from '@/components/Table';

const EnvironmentList = () => {
  const { data: environments, isLoading } = useEnvironmentsQuery();

  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const columns: (ColumnWithStrictAccessor<Column> & UseFiltersOptions<Column>)[] = useMemo(
    () => [
      {
        Header: <Box pl="5">Name</Box>,
        accessor: 'name',
        width: 1,
        disableToggle: true,
        Filter: SearchColumnFilter,
        Cell: ({ cell: { value } }) => (
          <Flex align="center">
            <UiIcon name="organization" color="grey-darker" mr="2" />
            <Box fontWeight="medium" color="grey-darker">
              {value}
            </Box>
          </Flex>
        ),
      },
      {
        Header: 'URL',
        accessor: 'url',
        name: 'URL',
        width: 2,
        Filter: SearchColumnFilter,
        disableSortBy: true,
        Cell: ({ cell: { value } }) => (
          <Link
            href={value as string}
            isExternal
            target="_blank"
            fontWeight="bold"
            _hover={{ textDecoration: 'underline', color: 'primary' }}
            _focus={{ outline: 'none' }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Database',
        accessor: 'db',
        name: 'Database',
        width: 1,
        Filter: SearchColumnFilter,
        disableSortBy: true,
      },
      {
        Header: '',
        accessor: 'id',
        disableToggle: true,
        width: '50px',
        minWidth: 50,
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    []
  );

  // react-table requires this to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useTable#table-options
  const content = useMemo(
    () =>
      environments?.map(({ name, url, database, id }) => ({
        name,
        url,
        db: database,
        id: (
          <Box _hover={{ color: 'primary' }} textAlign="center">
            <NavLink to={`/environments/${id}`}>
              <UiIcon name="view-solid" />
            </NavLink>
          </Box>
        ),
      })) || [],
    [environments]
  );

  // react-table requires initialState.sortBy and initialState.filters to be memoized
  // https://react-table-v7.tanstack.com/docs/api/useSortBy#table-options
  // https://react-table-v7.tanstack.com/docs/api/useFilters#table-options
  const options = {
    initialState: {
      sortBy: useMemo(
        () => [
          {
            id: 'name',
            desc: false,
          },
        ],
        []
      ),
      filters: useMemo(() => [], []),
    },
    defaultColumn: {
      disableFilters: false,
    },
    disableSortBy: false,
  };

  return (
    <>
      <Box fontSize="2xl" fontWeight="medium" mb="5">
        Environments
      </Box>
      <Table
        isLoading={isLoading}
        placeholder={<NoActivity />}
        data={content}
        columns={columns}
        options={options}
        placeholderText="No environments to show"
      />
    </>
  );
};

export default EnvironmentList;
