import React, { useState } from 'react';
import { Menu, MenuList, MenuItem, MenuButton, MenuButtonProps, Checkbox, Box } from '@chakra-ui/react';

import { UiIcon } from '@/components/ui-components';

type Option = {
  label: string;
  value: string;
};

type UiSelectMenuProps = {
  options: Option[];
  onChange: (selectedValue: string) => void;
  buttonProps?: MenuButtonProps;
  defaultValue?: string;
};

const UiSelect = ({ options, buttonProps, onChange, defaultValue = '' }: UiSelectMenuProps): JSX.Element => {
  const [selectedOption, setSelectedOptions] = useState<string>(defaultValue);

  const onSelect = (value: string) => () => {
    setSelectedOptions(value);
    onChange(value);
  };

  return (
    <Menu isLazy>
      {({ isOpen }) => (
        <>
          <MenuButton
            w="full"
            h="full"
            textAlign="left"
            border="default"
            borderColor="grey"
            borderRadius="md"
            color="grey-darker"
            position="relative"
            display="flex"
            py="1"
            pl="2"
            pr="5"
            _hover={{ borderColor: 'primary' }}
            {...buttonProps}
          >
            <Box fontStyle={selectedOption !== defaultValue ? 'italic' : 'normal'}>{selectedOption}</Box>

            <UiIcon
              name={isOpen ? 'chevron-up' : 'chevron-down'}
              position="absolute"
              right="0"
              top="0"
              bottom="0"
              mr="1"
              my="auto"
            />
          </MenuButton>

          <MenuList>
            <Box maxHeight="300px" overflowY="auto">
              {options.map((option, index) => (
                <MenuItem
                  py="2"
                  px="3"
                  key={`${option.value}-${index}`}
                  value={option.value}
                  icon={<Checkbox size="small" pr="1" isChecked={selectedOption === option.value} />}
                  onClick={onSelect(option.value)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default UiSelect;
