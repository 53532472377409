import React, { useCallback, useState } from 'react';
import { Menu, MenuList, MenuButtonProps, Box, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';

import { CancelButtonFilter, OpenButtonFilter, FilterHeader } from '@/components/column-filters';
import { UiIcon } from '@/components/ui-components';

type SearchFilterProps = {
  value: string;
  onChange: (e: any) => void;
  buttonProps?: MenuButtonProps;
};

const UiSearchFilter = ({ value, buttonProps, onChange }: SearchFilterProps): JSX.Element => {
  const color = value.length ? 'primary' : 'grey';

  const [isFocused, setFocused] = useState<boolean>(false);

  const onClear = useCallback(() => onChange(''), [onChange]);
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value);

  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <Menu isLazy closeOnSelect={false} placement="bottom-end">
      {({ isOpen }) => (
        <>
          {value.length && !isOpen ? <CancelButtonFilter onClickCross={onClear} /> : null}

          <OpenButtonFilter color={color} buttonProps={buttonProps} />

          <MenuList width="350px">
            <FilterHeader onClickClear={onClear} />

            <Box>
              <InputGroup my="2" mx="3" width="auto" color="grey-dark">
                <InputLeftElement pointerEvents="none">
                  <UiIcon name="search" color={isFocused ? 'primary' : 'grey-dark'} />
                </InputLeftElement>
                <Input
                  value={value}
                  variant="rounded"
                  onChange={onChangeValue}
                  placeholder="Search"
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
              </InputGroup>
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default UiSearchFilter;
