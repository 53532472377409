import Keycloak from 'keycloak-js';
import { removeCookie, setCookie } from 'typescript-cookie';

import { axiosInstance } from './utils';

export default async function authenticate() {
  let shouldLogin = true;

  const keycloak = new Keycloak({
    clientId: window.config.DOME_CLIENT_ID,
    url: window.config.DOME_AUTH_SERVER_URL,
    realm: window.config.DOME_REALM,
  });

  keycloak.onAuthSuccess = () => {
    shouldLogin = false;
  };

  keycloak.onAuthRefreshSuccess = () => {
    shouldLogin = false;
  };

  await keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: false,
  });

  // don't execute the login again if authentication was successful
  if (shouldLogin) {
    await keycloak.login({
      redirectUri: window.location.href,
      idpHint: window.config.REACT_APP_IDP_HINT || undefined,
    });
  }

  if (!keycloak.token) {
    axiosInstance.defaults.headers.common['Authorization'] = '';
    removeCookie('access_token');
  } else {
    const bearer = 'Bearer ' + keycloak.token;
    setCookie('access_token', keycloak.token);

    axiosInstance.defaults.headers.common['Authorization'] = bearer;
  }

  return keycloak.token;
}
