import React, { useCallback, useMemo } from 'react';
import { UseFiltersColumnProps } from 'react-table';

import { UiSelectFilter } from '@/components/ui-components';

type SelectColumnProps = {
  column: UseFiltersColumnProps<Column> & { id: string };
  defaultValues?: string[];
};

const SelectColumnFilter = ({
  column: { setFilter, preFilteredRows, id: accessor },
  defaultValues,
}: SelectColumnProps) => {
  const options = useMemo(() => {
    // const _options = new Set<{ label: string; value: string }>();
    const _options = new Set<string>();

    preFilteredRows.forEach(row => {
      // if contains an array of strings, get all unique options
      if (Array.isArray(row.values[accessor])) {
        row.values[accessor].forEach((role: string) => {
          // on each row, this foreach gets only the values from the column with accessor
          // and adds them to the Set above
          _options.add(role);
        });
        // else get the column with accessor and add it to the Set above
      } else {
        _options.add(row.values[accessor]);
      }
    });

    return Array.from(_options.values())
      .sort()
      .map(option => ({ label: option, value: option }));
  }, [accessor, preFilteredRows]);

  const onChange = useCallback((roles: string[]) => setFilter(roles), [setFilter]);

  return <UiSelectFilter options={options} onChange={onChange} defaultValues={defaultValues} />;
};

export default SelectColumnFilter;
