import React from 'react';

import { UiIcon } from '@/components/ui-components';

type CancelButtonProps = {
  onClickCross: () => void;
};

const CancelButtonFilter = ({ onClickCross }: CancelButtonProps) => (
  <UiIcon
    name="cross"
    color="error"
    mr={2}
    bg={'transparent'}
    p={1}
    borderRadius="50%"
    w="24px"
    h="24px"
    lineHeight="1"
    _focus={{
      outline: 'none',
      bg: 'error-lighter',
    }}
    _hover={{
      bg: 'error-lighter',
    }}
    _active={{
      bg: 'error-lightest',
    }}
    onClick={onClickCross}
    cursor="pointer"
  />
);

export default CancelButtonFilter;
