import React from 'react';
import { createIcon } from '@chakra-ui/react';

const NoContacts = () => {
  const NoContacts = createIcon({
    viewBox: '0 0 180 120',
    path: (
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#4093FF"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(10 8)"
      >
        <path strokeWidth="1.818" d="M29.545 68.986H8h21.545zm99 0h20.915-20.915z" />
        <path
          strokeWidth="2"
          d="M102.11 36H119a3 3 0 0 1 3 3v56a3 3 0 0 1-3 3H39a3 3 0 0 1-3-3V39a3 3 0 0 1 3-3h16.234"
        />
        <path
          strokeWidth="1.818"
          d="M117.225 11.672V6.637m-2.503 2.518h5.006m-73.722.914V0M41 5.035h10.011m-36.508 45V45M12 47.517h5.006"
        />
        <path
          strokeWidth="2"
          d="M68 31v-1a3 3 0 0 1 3-3h16a3 3 0 0 1 3 3v1h9.378a3 3 0 0 1 3 3v5a3 3 0 0 1-3 3H58.622a3 3 0 0 1-3-3v-5a3 3 0 0 1 3-3H68z"
        />
        <path
          strokeWidth="1.818"
          d="M0 69h4.534M155 69h4.534M137.636 16.622V14.73m3.972 3.545l1.342-1.327m.308 5.308h1.886m-3.536 4l1.342 1.326m-5.314.328v1.872m-3.99-3.527l-1.324 1.327m-.326-5.326h-1.868m3.518-3.981l-1.324-1.327"
        />
        <path
          strokeWidth="2"
          d="M61.857 67.774a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM72.968 79.47c-1.525-4.253-5.73-7.309-10.678-7.309-5.258 0-9.677 3.452-10.933 8.123M87 59.5h24.995M87 65.5h21.287M87 71.5h14.95"
        />
        <circle cx="62" cy="67" r="17" strokeWidth="2" />
      </g>
    ),
  });

  return <NoContacts width="180px" height="120px" />;
};

export default NoContacts;
