import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';

import { store } from '@/store';
import { authenticate } from '@/store/helpers';
import reportWebVitals from '@/reportWebVitals';

import theme from '@/theme';
import App from '@/App';

import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

authenticate()
  .then(() =>
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <ChakraProvider theme={theme} resetCSS>
              <App />
            </ChakraProvider>
          </BrowserRouter>
        </Provider>
      </React.StrictMode>
    )
  )
  .catch(() => <div>Authentication error</div>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
