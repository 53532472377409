import React from 'react';
import { MenuGroup, Flex } from '@chakra-ui/react';

import { UiIcon } from '@/components/ui-components';

type FilterHeaderProps = { onClickClear: () => void };

const FilterHeader = ({ onClickClear }: FilterHeaderProps) => (
  <MenuGroup title={undefined}>
    <Flex justify="space-between" align="center" px="3" py="1" fontSize="sm" fontWeight="bold">
      <span>Filter</span>

      <Flex
        align="center"
        color="primary"
        cursor="pointer"
        px={2}
        py={1}
        onClick={onClickClear}
        _hover={{
          textDecoration: 'underline',
        }}
      >
        <UiIcon name="cross" w="10px" h="10px" mr="1" />
        Clear filter
      </Flex>
    </Flex>
  </MenuGroup>
);

export default FilterHeader;
