import React, { useCallback } from 'react';
import { UseFiltersColumnProps } from 'react-table';

import { UiSearchFilter } from '@/components/ui-components';

type SearchColumnProps = {
  column: UseFiltersColumnProps<Column>;
};

const SearchColumnFilter = ({ column: { filterValue, setFilter } }: SearchColumnProps) => {
  const onChange = useCallback((value?: string) => setFilter(value || undefined), [setFilter]);

  return <UiSearchFilter value={filterValue || ''} onChange={onChange} />;
};

export default SearchColumnFilter;
