import React from 'react';
import { useTable, useFlexLayout, useSortBy, useFilters, ColumnWithStrictAccessor, TableOptions } from 'react-table';
import { Box, Flex, Spinner } from '@chakra-ui/react';

import { UiColumnToggle, UiIcon } from '@/components/ui-components';
import { NoItems } from '@/components/ui-placeholder';

type TableProps = {
  isLoading?: boolean;
  data: Column[];
  columns: ColumnWithStrictAccessor<Column>[];
  options?: Omit<TableOptions<object>, 'columns' | 'data'>;
  placeholder?: JSX.Element;
  placeholderText?: string | JSX.Element;
};

const EnvironmentTable = ({ isLoading, data, columns, options = {}, placeholder, placeholderText }: TableProps) => {
  const _options = {
    disableSortRemove: true,
    disableSortBy: true,
    defaultColumn: {
      disableFilters: true,
    },
    ...options,
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, allColumns } = useTable(
    {
      columns,
      data,
      ..._options,
    },
    useFilters,
    useSortBy,
    useFlexLayout
  );

  return (
    <Box>
      <Box border="default" borderColor="grey" borderRadius="default" {...getTableProps()}>
        {headerGroups.map(headerGroup => (
          <Box
            {...headerGroup.getHeaderGroupProps()}
            borderBottom="default"
            borderColor="grey"
            position="relative"
            pr="43px"
            height="44px"
          >
            {headerGroup.headers.map((column, index) => (
              <Flex
                px="2"
                py="2"
                justify="space-between"
                align="center"
                position="relative"
                {...column.getHeaderProps()}
              >
                <Flex color="grey-dark" fontSize="sm" align="center" {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                  {column.isSorted ? (
                    <UiIcon name="arrow-down" ml="1" transform={column.isSortedDesc ? undefined : 'rotate(0.5turn)'} />
                  ) : null}
                </Flex>

                <div>{column.canFilter ? column.render('Filter') : null}</div>

                {index !== headerGroup.headers.length - 1 ? (
                  <Box
                    position="absolute"
                    right="0"
                    top="0"
                    bottom="0"
                    my="2"
                    borderRight="default"
                    borderColor="grey"
                  ></Box>
                ) : null}
              </Flex>
            ))}

            <Flex position="absolute" right="7px" h="full" px="1" align="center">
              <UiColumnToggle options={allColumns} />
            </Flex>
          </Box>
        ))}

        <Box position="relative" height="calc(100vh - 300px)" overflowY="scroll" {...getTableBodyProps()}>
          {isLoading ? (
            <Spinner
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              m="auto"
              width="50px"
              height="50px"
              color="primary"
              thickness="4px"
              speed="0.75s"
            />
          ) : rows.length ? (
            rows.map(row => {
              prepareRow(row);

              return (
                <Flex
                  pr="36px"
                  py="2"
                  color="grey-dark"
                  fontSize="sm"
                  align="center"
                  borderBottom="default"
                  borderColor="grey"
                  _hover={{
                    bg: 'grey-lightest',
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => (
                    <Box px="2" overflowWrap="break-word" alignSelf="stretch" {...cell.getCellProps()}>
                      <Box h="full" w="full" display="flex" alignItems="center">
                        {cell.render('Cell')}
                      </Box>
                    </Box>
                  ))}
                </Flex>
              );
            })
          ) : (
            <Flex
              position="absolute"
              top="0"
              bottom="0"
              left="0"
              right="0"
              direction="column"
              align="center"
              justify="center"
            >
              {placeholder || <NoItems />}
              <Box mt="4" textAlign="center" fontWeight="medium" fontSize="md">
                {placeholderText || 'no items found'}
              </Box>
            </Flex>
          )}
        </Box>
      </Box>

      <Box mt="3" color="grey" fontSize="sm" fontWeight="bold">
        {rows.length}/{rows.length} items
      </Box>
    </Box>
  );
};

export default EnvironmentTable;
