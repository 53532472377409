import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Alert: ComponentStyleConfig = {
  baseStyle: {
    title: {
      fontWeight: 'normal',
    },
    icon: {
      w: 0,
      h: 0,
      marginEnd: 0,
    },
  },
  variants: {
    solid: ({ status }) => ({
      container: {
        bg: status === 'success' ? 'blue-darkest' : status,
        color: status === 'success' ? 'white' : 'grey-darkest',
      },
      description: {
        color: status === 'success' ? 'grey-lighter' : 'grey-darker',
      },
    }),
  },
  sizes: {
    large: {
      container: {
        fontSize: 'alert-lg',
      },
      description: {
        fontSize: 'alert-message',
      },
    },
    medium: {
      container: {
        fontSize: 'alert-md',
      },
      description: {
        fontSize: 'alert-message',
      },
    },
    small: {
      container: {
        fontSize: 'alert-sm',
      },
      description: {
        fontSize: 'alert-message',
      },
    },
  },
};

export default Alert;
