import React from 'react';
import { createIcon } from '@chakra-ui/react';

const NoActivity = () => {
  const NoActivity = createIcon({
    viewBox: '0 0 180 120',
    path: (
      <g fill="none" fillRule="evenodd">
        <path
          d="M40.525 88.747H18.98h21.545zm92.566 0h27.349-27.35zM11.109 89.004h4.534M164.357 89.004h4.534"
          stroke="#4093FF"
          strokeWidth="1.818"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g stroke="#4093FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.818">
          <path d="M147.636 54.8v-1.897M151.608 56.46l1.342-1.332M153.258 60.455h1.886M151.608 64.468l1.342 1.332M147.636 66.128v1.879M143.646 64.468l-1.324 1.332M141.996 60.455h-1.868M143.646 56.46l-1.324-1.332" />
        </g>
        <g stroke="#4093FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.818">
          <path d="M125.546 14.803V9.768M123.044 12.285h5.005" />
        </g>
        <g stroke="#4093FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.818">
          <path d="M54.327 13.2V3.13M49.321 8.165h10.011" />
        </g>
        <g stroke="#4093FF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.818">
          <path d="M27.068 71.33v-5.035M24.566 68.812h5.005" />
        </g>
        <g fill="#4093FF">
          <path
            d="M114.576 83.456c-.388 1.059-.943 2.127-1.661 3.227-1.106 1.692-2.578 3.462-4.776 5.772-.153-1.154-1.051-1.225-1.984-1.483 2.913-3.046 4.903-5.508 5.901-7.83.06.014.12.025.18.032l2.34.282zm-11.743-19.004a276.6 276.6 0 0 0-2.725-2.76c-16.172-16.171-33.69-18.495-39.95-12.235-6.26 6.26-3.936 23.778 12.236 39.95 2.244 2.244 4.234 4.179 6.029 5.813-1.092-.106-1.659.143-1.659.143s-.658.18-.897.83a207.306 207.306 0 0 1-5.205-5.054c-7.894-7.894-13.096-16.616-15.177-24.83-1.979-7.81-.962-14.696 2.934-18.591 3.896-3.896 10.781-4.913 18.59-2.934 8.215 2.08 16.937 7.282 24.831 15.176.936.936 1.812 1.823 2.631 2.665l-1.638 1.827zm.636 32.688c-2.465 2.364-4.318 3.919-6.085 5.074-2.583 1.687-4.994 2.471-7.515 2.323.02-.24-.002-.542-.017-.922-.022-.58-.183-1.036-.692-1.623 3.879.638 7.314-1.59 12.333-6.367.347.735 1.161.968 1.161.968s.313.313.815.547z"
            fillRule="nonzero"
          />
          <path
            d="M82.503 71.78c-3.03 3.03-7.951 3.02-10.993-.023-3.042-3.042-3.053-7.964-.024-10.993 3.03-3.03 7.952-3.019 10.994.023 3.042 3.042 3.053 7.964.023 10.994zm-1.74-1.739c2.073-2.072 2.066-5.44-.015-7.522-2.082-2.081-5.45-2.088-7.522-.016-2.073 2.073-2.066 5.44.016 7.522 2.081 2.082 5.45 2.089 7.522.016z"
            fillRule="nonzero"
          />
          <path d="M104.971 108.854a1.22 1.22 0 0 1 .002-1.73 1.221 1.221 0 0 1 1.73-.002l5.809 5.809a1.22 1.22 0 0 1-.002 1.73 1.221 1.221 0 0 1-1.73.002l-5.809-5.809zm12.703-12.702a1.22 1.22 0 0 1 0-1.73 1.221 1.221 0 0 1 1.732-.002l5.808 5.808a1.22 1.22 0 0 1-.001 1.731 1.221 1.221 0 0 1-1.731.001l-5.808-5.808zm-4.895 7.808a1.226 1.226 0 1 1 1.732-1.732l8.694 8.693a1.226 1.226 0 1 1-1.733 1.732l-8.693-8.693zM53.334 44.25a1.239 1.239 0 0 1-.012-1.75 1.229 1.229 0 0 1 1.744.01l5.173 5.196c.485.487.494 1.268.012 1.751a1.229 1.229 0 0 1-1.744-.011l-5.173-5.196z" />
          <path
            d="M110.806 100.193c-.904.904-2.116 1.178-3.347.929-.82-.166-1.64-.554-2.535-1.14-1.388-.91-3.04-2.36-5.121-4.442-2.094-2.093-3.673-3.875-4.699-5.362-.647-.937-1.094-1.783-1.327-2.597-.375-1.314-.202-2.629.794-3.624.995-.995 2.31-1.168 3.624-.793.814.232 1.659.68 2.597 1.327 1.487 1.026 3.268 2.605 5.362 4.698 2.08 2.081 3.532 3.734 4.441 5.122.586.894.974 1.715 1.14 2.534.25 1.232-.025 2.443-.93 3.348zm-1.74-1.74c1.116-1.116-.962-3.849-4.644-7.532-3.683-3.682-6.996-6.34-8.112-5.224-1.116 1.116 1.542 4.429 5.225 8.111 3.682 3.683 6.415 5.761 7.531 4.645zM91.017 108.118c-.118 3.485-.504 5.326-2.074 6.535-1.241.956-2.767.956-4.259.248-1.072-.51-2.23-1.42-3.587-2.777-2.468-2.468-4.47-5.83-5.724-9.263-1.342-3.675-1.706-6.613-.146-8.173 1.34-1.34 3.167-1.667 5.166-.872 1.676.668 3.631 2.163 6.125 4.657.557.557 2.328 2.295 2.658 2.632.814.834 1.263 1.394 1.586 2.097.366.795.452 1.513.403 2.5 0-.004-.125 1.71-.148 2.416zm-2.454-.093c.153-4.524 1.026-3.016-3.777-7.82-4.804-4.803-6.703-4.893-7.82-3.777-1.116 1.116 1.06 9.16 5.863 13.964 4.804 4.803 5.581 2.156 5.734-2.367z"
            fillRule="nonzero"
          />
        </g>
        <g transform="translate(99.576 57.493)">
          <path
            d="M8.867 4.024c3.036-4.878 7.955-4.881 10.993 0l6.961 11.185c3.036 4.878.84 8.832-4.901 8.832H6.808c-5.743 0-7.94-3.95-4.902-8.832l6.96-11.185z"
            fill="#4093FF"
          />
          <rect fill="#FFF" x="12.768" y="4.808" width="2.394" height="12.021" rx="1.197" />
          <ellipse fill="#FFF" cx="13.965" cy="19.634" rx="1.197" ry="1.202" />
        </g>
      </g>
    ),
  });

  return <NoActivity width="180px" height="120px" />;
};

export default NoActivity;
