import React, { useMemo } from 'react';
import { Button, ButtonProps, Spinner } from '@chakra-ui/react';

type UiButtonProps = Omit<ButtonProps, 'type'> & {
  type?: 'primary' | 'error' | 'warning' | 'dark' | 'default' | 'success';
  inverted?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  size?: 'smallest' | 'small' | 'medium' | 'large';
  isLoading?: boolean;
  children?: string | JSX.Element | React.ReactNode;
};

const UiButton = ({
  type = 'primary',
  inverted,
  ghost,
  size,
  disabled,
  isLoading,
  children,
  ...restProps
}: UiButtonProps) => {
  const _type = type === 'default' ? 'secondary' : type;

  const styles = useMemo(
    () => ({
      size,
      opacity: disabled || isLoading ? 0.5 : 1,
      cursor: disabled || isLoading ? 'not-allowed' : 'pointer',
      shadow: ghost ? undefined : 'default',
      outline: 'none',
      bg: inverted ? 'white' : ghost ? 'transparent' : _type,
      color: inverted || ghost ? _type : 'white',
      borderColor: ghost ? 'transparent' : _type,
      _hover:
        disabled || isLoading
          ? {
              bg: inverted ? 'white' : ghost ? 'transparent' : _type,
            }
          : {
              outline: 'none',
              color: inverted || ghost ? `${_type}-hover` : 'white',
              bg: inverted ? 'white' : ghost ? `${_type}-lightest` : `${_type}-hover`,
              borderColor: ghost ? 'transparent' : `${_type}-hover`,
              boxShadow: 'menu',
            },
      _active:
        disabled || isLoading
          ? {
              bg: inverted ? 'white' : ghost ? 'transparent' : _type,
            }
          : {
              outline: 'none',
              color: inverted || ghost ? `${_type}-active` : 'white',
              bg: inverted ? 'white' : ghost ? `${_type}-lighter` : `${_type}-active`,
              borderColor: ghost ? 'transparent' : `${_type}-active`,
              boxShadow: 'shadow-button',
            },
      _focus: {
        outline: 'none',
      },
    }),
    [_type, inverted, ghost, size, disabled, isLoading]
  );

  return (
    <Button {...styles} {...restProps} isDisabled={disabled || isLoading}>
      {children}
      {isLoading ? <Spinner speed="0.75s" size="sm" ml="2" /> : null}
    </Button>
  );
};

export default UiButton;
