const Modal = {
  baseStyle: {
    header: {
      fontSize: 'xl',
      px: null,
      py: null,
      fontWeight: 600,
    },
    body: {
      maxH: '100%',
      fontSize: 'md',
      px: null,
      py: null,
    },
    closeButton: {
      top: null,
      insetEnd: null,
      position: 'relative',
      borderRadius: 'full',
      color: 'grey',
      _focus: {
        outline: 'none',
      },
      _hover: {
        bg: 'primary-lightest',
        color: 'primary',
      },
      _active: {
        bg: 'primary-lighter',
        color: 'primary',
      },
    },
    footer: {
      px: null,
      py: null,
    },
  },
  sizes: {
    small: {
      dialog: {
        width: '320px',
        maxHeight: '400px',
      },
    },
    medium: {
      dialog: {
        width: '640px',
        maxHeight: '400px',
      },
    },
    large: {
      dialog: {
        width: '800px',
        maxHeight: '800px',
      },
    },
    xlarge: {
      dialog: {
        width: '1200px',
        maxHeight: '800px',
      },
    },
  },
};

export default Modal;
