import colors from './colors';
import shadows from './shadows';

const config = {
  theme: {
    colors,
    fontSizes: {
      xs: '1rem',
      sm: '1.2rem',
      md: '1.4rem',
      lg: '1.6rem',
      xl: '2rem',
      '2xl': '2.4rem',
      '3xl': '2.8rem',
      '4xl': '3.6rem',
      '5xl': '4.8rem',
      'alert-sm': '0.9em',
      'alert-md': '1em',
      'alert-lg': '1.2em',
      'alert-message': '0.9em',
    },

    borders: {
      default: '1px solid',
      '0': '0 solid',
      '2': '2px solid',
      '3': '3px solid',
      '4': '4px solid',
      '6': '6px solid',
      '8': '8px solid',
      '10': '10px solid',
    },

    radii: {
      none: '0',
      sm: '0.25rem',
      md: '0.5rem',
      default: '2px',
      lg: '0.75rem',
      xl: '1rem',
      full: '9999px',
    },

    sizes: {
      auto: 'auto',
      '0': '0',
      px: '1px',
      '1': '5px',
      '2': '10px',
      '3': '15px',
      '4': '20px',
      '5': '25px',
      '6': '30px',
      '7': '35px',
      '8': '40px',
      '9': '45px',
      '10': '50px',
      '11': '55px',
      '12': '60px',
      '13': '65px',
      '14': '70px',
      '15': '75px',
      '16': '80px',
      '17': '85px',
      '18': '90px',
      '19': '95px',
      '20': '100px',
      '24': '120px',
      '32': '160px',
      '40': '200px',
      '44': '220px',
      '48': '240px',
      '50': '250px',
      '60': '300px',
      '64': '320px',
      '70': '350px',
      '80': '400px',
      l: '600px',
      xl: '800px',
      '1-2': '50%',
      '1-3': '33.33333%',
      '2-3': '66.66667%',
      '1-4': '25%',
      '3-4': '75%',
      '1-5': '20%',
      '2-5': '40%',
      '3-5': '60%',
      '4-5': '80%',
      '1-6': '16.66667%',
      '5-6': '83.33333%',
      '3-10': '30%',
      half: '50%',
      full: '100%',
      screenW: '100vw',
      screenH: '100vh',
    },

    space: {
      auto: 'auto',
      px: '1px',
      '0': '0',
      '1': '5px',
      '2': '10px',
      '3': '15px',
      '4': '20px',
      '5': '25px',
      '6': '30px',
      '7': '35px',
      '8': '40px',
      '9': '45px',
      '10': '50px',
      '11': '55px',
      '12': '60px',
      '13': '65px',
      '14': '70px',
      '15': '75px',
      '16': '80px',
      '17': '85px',
      '18': '90px',
      '19': '95px',
      '20': '100px',
      '24': '120px',
      '32': '160px',
      '40': '200px',
    },

    shadows,
    zIndices: {
      auto: 'auto',
      '0': 0,
      '10': 10, // input label
      '20': 20, // input
      '50': 50,
      '100': 100, // header
      '110': 110, // document-details, fullscreen. needs to be higher than navbar.
      '200': 200, // fixed
      'document-details-overlay': 220,
      'document-details': 230,
      '250': 250, // modal-overlay
      '300': 300, // modal
      '400': 400, // popover (context menus, right click menus, toasts ...)
      '500': 500, // tooltip
    },
  },
};

export default config;
